import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Link, useParams } from 'react-router-dom';
import {db, auth} from '../../firebase-config';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {collection,query,where,getDocs,getDoc,doc,arrayUnion,addDoc,runTransaction, Timestamp} from 'firebase/firestore';  
import { useForm } from 'react-hook-form';
import StorageModal from './StorageModal';
import BreadCrumb from '../BreadCrumb';
import { fetchDeceasedList, fetchNextOfKinList, fetchServicesList } from './deceasedService';
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';
import StatementModal from '../Deceased/StatementModal';
import PaymentModal from '../Deceased/PaymentModal';
import RefundModal from '../Deceased/RefundModal';
import { calculateAccumulatedStorageFees, capitalizeFirstLetter} from '../Deceased/helpers';
import { ToastContainer,toast } from 'react-toastify';
import PersonalDetails from './PersonalDetails';
import ActionButtons from './ActionButtons';
import RenderStorageAllocation from './RenderStorageAllocation';
import InvoiceHistory from './InvoiceHistory';


const pages = [
  { name: 'Deceased', to: '/deceased', component: Link, current: false },
  { name: 'List of Deceased', to: '/dtable', component: Link, current: false },
  { name: 'Deceased Details', to:'#', component: Link, current: true },  
 ]

export default function DecesedDetails() {
  const { id } = useParams();
  const [deceased, setDeceased] = useState(null);
  const [services, setServices] = useState([]);
  const [nextOfKin, setNextOfKin] = useState([]);
  const [storageModalOpen, setStorageModalOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFormSubmitSuccessful, setFormSubmitSuccessful] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentDate] = useState(new Date());
  const [ isStorageModalOpen, setIsStorageModalOpen] = useState(false);
  const [storageRates, setStorageRates] = useState([]);
  const [isStatementModalOpen, setIsStatementModalOpen] = useState(false);
  const handleGenerateStatementClick = () => {setIsStatementModalOpen(true);};
  const handleAmountPaidChange = (e) => { setAmountPaid(e.target.value);};  
  const openModal = () => {setModalOpen(true);};
  const openStorageModal = () => {setIsStorageModalOpen(true); };
  const closeStorageModal = () => {setIsStorageModalOpen(false); };
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const user = auth.currentUser;
  const [errorMessage, setErrorMessage] = useState(''); 

  
  // State to store payment status and amount paid
const [paymentStatus, setPaymentStatus] = useState("fully");
const [amountPaid, setAmountPaid] = useState('');
const {register, handleSubmit,formState: { errors },watch,reset} = useForm();



                
useEffect(() => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in
      setCurrentUser(user);
    } else {
      // User is signed out
      setCurrentUser(null);
    }
  });
}, []);

// define the deceased data-fetching logic in a function
const fetchDeceasedData = useCallback(async () => {
  const deceasedListResult = await fetchDeceasedList();
  // Access the 'allDeceased' array from the object returned by fetchDeceasedList
  const deceasedData = deceasedListResult.allDeceased.find((item) => item.id === id);
  setDeceased(deceasedData);
}, [id]); // id is a dependency here


const fetchServicesData = useCallback(async () => {
  const fetchedServices = await fetchServicesList();
  setServices(fetchedServices);
}, []); // no dependencies here

const fetchNextOfKinData = useCallback(async (nextOfKinId) => {
  const fetchedNextOfKin = await fetchNextOfKinList();
  const nextOfKinData = fetchedNextOfKin.find((item) => item.id === nextOfKinId);
  setNextOfKin(nextOfKinData);
  setIsLoading(false);
}, []); // no dependencies here

useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true);
    await fetchDeceasedData();
    await fetchServicesData();
  };
  setIsLoading(false);
  fetchData();
}, [fetchDeceasedData, fetchServicesData]); // add both functions as dependencies


useEffect(() => {
  if (deceased) {
    fetchNextOfKinData(deceased.nextOfKinId);
  }
}, [deceased, fetchNextOfKinData]); // add 'fetchNextOfKinData' to the dependency array



const handleModalClose = () => {
  // fetch the deceased data again
  fetchDeceasedData();
}

  useEffect(() => {
    const fetchStorageRates = async () => {
      const storageRatesSnapshot = await getDocs(collection(db, 'storageRates'));
      const storageRatesData = storageRatesSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setStorageRates(storageRatesData);
    };

    fetchStorageRates();
  }, []);


  useEffect(() => {
    if (deceased && deceased.id) {
      fetchInvoicesForDeceased(deceased.id)
        .then(invoices => setInvoices(invoices))
        .catch(error => console.error(error));
    }
  }, [deceased]);


  const fetchDeceased = async (deceasedId) => {
    const deceasedDocRef = doc(db, 'deceased', deceasedId);
    const deceasedSnapshot = await getDoc(deceasedDocRef);

    if (!deceasedSnapshot.exists()) {
      throw new Error('Deceased not found');
    }

    const deceasedData = deceasedSnapshot.data();
    const storageDescription = deceasedData.storageId
      ? await fetchStorageDescription(deceasedData.storageId)
      : null;

    return {
      id: deceasedSnapshot.id,
      ...deceasedData,
      storageDescription,
    };
  };

  
  const fetchInvoicesForDeceased = async (id) => {
    const invoiceQuery = query(
      collection(db, "invoices"),
      where("deceasedId", "==", id)
    );
    const querySnapshot = await getDocs(invoiceQuery);
    const invoiceList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return invoiceList;
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
  
    try {
      let { amountReceived, paymentSource, dateIncurred, ...restData } = data;  
      const deceasedRef = doc(db, 'deceased', deceased.id);
      const transactionsCol = collection(db, "transactions");
       // Convert dateIncurred to Firestore Timestamp
    const dateIncurredTimestamp = dateIncurred ? Timestamp.fromDate(new Date(dateIncurred)) : null;
    
    const paymentDateTimestamp = Timestamp.fromDate(new Date(dateIncurred));

    if (!dateIncurredTimestamp || !paymentDateTimestamp) {
      throw new Error("Invalid date provided");
    }
  
      const transactionData = {
        amount: parseFloat(amountReceived),
        dateIncurred: dateIncurredTimestamp,
        dateOfPosting: paymentDateTimestamp,
        sourceOfIncome: paymentSource,
        text: `Wallet Funded for Deceased: ${deceased.firstName} ${deceased.lastName}`,
        transactionType: "income",
        user: user.email
      };
  
      await runTransaction(db, async (transaction) => {
        const deceasedDoc = await transaction.get(deceasedRef);
        const oldWallet = parseFloat(deceasedDoc.data().wallet);
  
        const newWallet = oldWallet + transactionData.amount;
  
        const transactionDocRef = await addDoc(transactionsCol, transactionData);
        const newWalletTransaction = {
          transactionId: transactionDocRef.id,
          transactionType: 'credit',
          amount: transactionData.amount,
          date: dateIncurredTimestamp,
          description: `Wallet Funded for Deceased: ${deceased.firstName} ${deceased.lastName}`,
        };
  
        transaction.update(deceasedRef, {
          ...restData,
          wallet: newWallet,
          walletTransactions: arrayUnion(newWalletTransaction),
        });
      });
  
      handleEmailReceipt(amountReceived, paymentSource);
  
      setFormSubmitSuccessful(true);
      setIsLoading(false);
    } catch (error) {
      toast.error('Error updating wallet: ', error);
      setIsLoading(false);
    }
  };
  
     
   const fetchStorageDescription = async (storageId) => {
            const storageDocRef = doc(db, 'services', storageId);
            const storageSnapshot = await getDoc(storageDocRef);

            if (!storageSnapshot.exists()) {
              throw new Error('Storage not found');
            }

            return storageSnapshot.data().description;
          };
                                
        
          const onRefund = async (data) => {
            setIsLoading(true);
          
            try {
              let { amountToRefund, refundReason, ...restData } = data;
              const deceasedRef = doc(db, 'deceased', deceased.id);
              const transactionsCol = collection(db, "transactions");
          
              const transactionData = {
                amount: parseFloat(amountToRefund),
                dateIncurred: paymentDate,
                dateOfPosting: paymentDate,
                sourceOfIncome: "Wallet",
                text: `Wallet Refunded for Deceased: ${deceased.firstName} ${deceased.lastName}`,
                transactionType: "expense",
                user: user.email
              };
          
              await runTransaction(db, async (transaction) => {
                const deceasedDoc = await transaction.get(deceasedRef);
                const oldWallet = parseFloat(deceasedDoc.data().wallet);
          
                if (oldWallet < transactionData.amount) {
                  throw new Error('Insufficient funds');
                }
          
                const newWallet = oldWallet - transactionData.amount;
          
                const transactionDocRef = await addDoc(transactionsCol, transactionData);
                const newWalletTransaction = {
                  transactionId: transactionDocRef.id,
                  transactionType: 'debit',
                  amount: transactionData.amount,
                  date: new Date(),
                  description:  `Wallet Refunded for Deceased: ${deceased.firstName} ${deceased.lastName}`,
                };
          
                transaction.update(deceasedRef, {
                  ...restData,
                  wallet: newWallet,
                  walletTransactions: arrayUnion(newWalletTransaction),
                });
              });
          
              handRefundEmail(amountToRefund, refundReason);
          
              setFormSubmitSuccessful(true);
              setIsLoading(false);
            } catch (error) {
              console.error('Error refunding wallet: ', error);
              setIsLoading(false);
            }
          };
          
        
        const handleEmailReceipt = async (amountReceived, paymentSource) => {
          const functions = getFunctions();
          const sendHTMLEmailReceipt = httpsCallable(functions, 'sendHTMLEmailReceipt');
          
          try {
            await sendHTMLEmailReceipt({
              nextOfKinEmail: nextOfKin.nextOfKinEmail,
              deceasedName: `${deceased.firstName} ${deceased.lastName}`,
              amountReceived: amountReceived,
              paymentSource: paymentSource
            });
            
          
          } catch (error) {
            console.error('Failed to send email:', error);
          }
        };
        

        const handRefundEmail = async (amountToRefund, refundReason) => {
          const functions = getFunctions();
          const sendRefundEmail = httpsCallable(functions, 'sendRefundEmail');
          
          try {
            await sendRefundEmail({
              nextOfKinEmail: nextOfKin.nextOfKinEmail,
              deceasedName: `${deceased.firstName} ${deceased.lastName}`,
              amountToRefund: amountToRefund,
              refundReason: refundReason
            });
            
          
          } catch (error) {
            console.error('Failed to send email:', error);
          }
        };
           

        const handleStatementModalClose = () => {
          setIsStatementModalOpen(false);
          fetchDeceasedData();
        }
        
       
           const allowedRoles = ["Admin", "Finance", "User", "Auditor",  "Front-Desk"];
          const isAllowed = useRoleRestriction(allowedRoles);

          if (!isAllowed) {
            return < AccessDenied />
          }

  return (
    <>
      <BreadCrumb pages={pages} />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

    {deceased && (

      

            <div className="flex flex-wrap">
            
                <div className="w-full lg:w-1/2 px-2">
                  <div className="px-10 py-1 sm:px-6">
                   <h3 className="text-xl font-semibold leading-7 text-red-600"> {capitalizeFirstLetter(deceased.firstName)}{" "} {capitalizeFirstLetter(deceased.lastName)} {" "} Body Number: {deceased.bodyNumber} </h3>

                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details provided on arrival.</p>
                  </div>
                  <div className="border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                      
                      <PersonalDetails deceased={deceased} />
                        
                    
                        <ActionButtons
                          deceased={deceased}
                          setStorageModalOpen={setStorageModalOpen}
                          openModal={openModal}
                          setRefundModalOpen={setRefundModalOpen}
                          handleGenerateStatementClick={handleGenerateStatementClick}
                        />


                    </dl>
                  </div>
                </div>

                        <div className="w-full lg:w-1/2 px-4">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                          <div className="px-4 py-3 sm:px-6">
                            <h3 className="text-md leading-6 font-medium text-gray-900">
                        Storage Consumption Fees
                        </h3>
                        </div>
                        <div className="px-4 sm:px-2">
                        <RenderStorageAllocation deceased={deceased} />
                        </div>
                        </div>

                        <InvoiceHistory deceased={deceased} invoices={invoices} />
                        </div>
                    
            </div>

      )}

      <StorageModal
          isOpen={storageModalOpen}
          onRequestClose={() => setStorageModalOpen(false)}
          deceased={deceased}
          />

      {isStatementModalOpen && (
        <StatementModal
          deceased={deceased}
          invoices={invoices}
          user = {user.email}
          nextOfKinEmail={nextOfKin?.nextOfKinEmail} 
          onClose={handleStatementModalClose}
          accumulatedStorageFees={deceased.allocations ? calculateAccumulatedStorageFees(deceased.allocations) : 0}
        />
      )}


      <PaymentModal 
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
        isLoading={isLoading}
        errors={errors}
        isFormSubmitSuccessful={isFormSubmitSuccessful}
        resetForm={reset}
        onClose={() => fetchDeceasedData()} 
      
        nextOfKin={nextOfKin}
        />

        <RefundModal
        isOpen={refundModalOpen}
        onRequestClose={() => setRefundModalOpen(false) }
        onClose={() => fetchDeceasedData()} 
        onRefund={onRefund}
        deceased={deceased}
        invoices={invoices}
        nextOfKin={nextOfKin}
        user = {user.email}
        errorMessage = {errorMessage}
         />


    </>
  )
}
