// CostSummary.js
import React from 'react';
import {  calculateAccumulatedStorageFees} from '../Deceased/helpers';

const CostSummary = ({  formatCurrency,deceased, invoices }) => {

  const accumulatedStorageFees = calculateAccumulatedStorageFees(deceased.allocations, deceased.exitDate || new Date());

  const InvoiceTotal = invoices.reduce((sum, invoice) => {    
        return sum + (invoice.total);        
  }, 0);

  const totalCostsIncurred =  accumulatedStorageFees + InvoiceTotal;

  return (
    <div className="p-4 bg-white rounded-lg shadow">
    <h3 className="font-semibold text-lg mb-3">Summary of Costs Incurred:</h3>
    <ul className="list-disc pl-5 space-y-2">
        <li className='text-sm'>Accumulated Storage Fees: {formatCurrency(accumulatedStorageFees)}</li>
       
          <li className='text-sm'>Total Invoices: {formatCurrency(InvoiceTotal)}</li>
        
      </ul>
      <p className="mt-2 font-bold text-blue-600">Total: {formatCurrency(totalCostsIncurred)}</p>
    </div>
  );
};

export default CostSummary;
