import React, {useState, useEffect} from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { useForm } from 'react-hook-form';
import NextOfKinModal from '../NextOfKin/NextOfKinModal';
import Select from 'react-select';
import { fetchNextOfKinList as fetchNextOfKin } from '../NextOfKin/nextOfKinService';


const DeceasedData = ({ onNext,onNewNextOfKin, referralAgentsList,formData, onFormDataChange }) => {
  const { register,setValue,
     handleSubmit,
     formState: { errors },
    watch, } = useForm();

    const [daysSpent, setDaysSpent] = useState(0);
    const dateDepositedWatched = watch('dateDeposited', formData.dateDeposited);


useEffect(() => {
    if (dateDepositedWatched) {
        const currentDate = new Date();
        const depositDate = new Date(dateDepositedWatched);

        const timeDiff = currentDate - depositDate; // difference in milliseconds
        const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        setDaysSpent(dayDiff);
    }
}, [dateDepositedWatched]);



    useEffect(() => {
      // Update the form fields with formData whenever it changes
      for (const field in formData) {
        setValue(field, formData[field]);
      }
    }, [formData, setValue]);

    const onSubmit = (formData) => {
      const mergedData = {
        ...formData,
        daysSpent
      };
    
      onFormDataChange(mergedData);
      onNext();
    };
    

 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ nextOfKinList, setNextOfKinList] = useState([]);
  const [showModal, setShowModal] = useState(false); 
  const currentDate = new Date().toISOString().slice(0, 10);
  
  

  useEffect(() => {
    const fetchNextOfKinList = async () => {
      try {
        const nextOfKinList = await fetchNextOfKin();
        setNextOfKinList(nextOfKinList);
      } catch (error) {
        console.error('Error fetching next of kin list:', error);
      }
    };

    fetchNextOfKinList();
  }, []);
    

    const handleOpenModal = () => {
        setIsModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setIsModalOpen(false);
      };
    
    
      const handleNewNextOfKin = (nextOfKin) => {
        // Update the next of kin list in your state here
        setNextOfKinList([...nextOfKinList, nextOfKin]);
           
      };     

      
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleCreateNextOfKin = async (nextOfKinData) => {
    try {
      const nextOfKinDocRef = await addDoc(collection(db, "nextOfKin"), nextOfKinData);
      console.log("Next of kin added successfully");
      onNewNextOfKin({ id: nextOfKinDocRef.id, ...nextOfKinData }); // Call onNewNextOfKin prop
    } catch (error) {
      console.error("Error adding next of kin: ", error);
    }
  };


  // Function to convert nextOfKinList to a format suitable for react-select
const getOptionsFromNextOfKin = (nextOfKinList) => {
  return nextOfKinList.map(nextOfKin => ({
    label: `${nextOfKin.nextOfKinName} (${nextOfKin.nextOfKinRelationship})`,
    value: nextOfKin.id
  }));
};
  


  return (

    <>

<div className="flex justify-center items-center bg-gray-100">
<form onSubmit={handleSubmit(onSubmit)} className=" w-full max-w-5xl m-auto bg-white shadow-md rounded px-8 pt-1 pb-4 mb-4">
   
  <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-14 sm:grid-cols-3">

                <div className="sm:col-span-1">
                  <label htmlFor="firstName" className="block text-gray-700 text-sm font-bold mb-1">First Name:</label>
                  <div className="mt-2">
                        <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          {...register('firstName', { required: true })}
                        />
                      {errors.firstName && <p className='text-xs mt-1 text-red-700'>First Name of Deceased is Required</p>}
                    </div>
                    </div>
               
                  <div className="sm:col-span-1">
                    <label htmlFor="lastName" className="block text-gray-700 text-sm font-bold mb-1">Last Name:</label>
                    <div className="mt-2">
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      {...register('lastName', { required: true })}
                    />
                    {errors.lastName && <p className='text-xs mt-1 text-red-700'>Last Name of Deceased is Required</p>}
                  </div>
                  </div>

                  <div className="sm:col-span-1">
                  <label htmlFor="age" className="block text-gray-700 text-sm font-bold mb-1">Age</label>
                  <div className="mt-2">
                  <input
                    type="number"
                    id="age"
                    name="age"
                    {...register('age', { required: true, min: 0 })} 
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                  {errors.age && <p className='text-xs mt-1 text-red-700'>Age of Deceased is Required</p>}
                  </div>
                  </div>

                  <div className="sm:col-span-1">
                    <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Gender of Deceased:</label>
                    <div className="mt-1">
                    <select {...register('gender', { required: true })}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                      focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                        
                        </select>
                        {errors.gender && <p className='text-xs mt-1 text-red-700'>Gender is required</p>}
                    </div>
                    </div>

                    <div className="sm:col-span-1">
                      <label htmlFor="causeOfDeath"className="block text-sm font-medium text-gray-700">Cause of Death</label>
                      <div className="mt-1">
                      <select {...register('causeOfDeath', { required: true })}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                        focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <option value="">Select Cause of Death</option>
                          <option value="Natural Causes">Natural Causes</option>
                          <option value="Accident">Accident</option>
                          <option value="Suicide">Suicide</option>
                          <option value="Unknown">Unknown</option>
                          <option value="Old Age">Old Age</option>
                          <option value="Brief Illness">Brief Illness</option>
                          <option value="Terminal Illness">Terminal Illness</option>
                          <option value="Violence">Violence</option>
                          </select>
                          {errors.causeOfDeath && <p className='text-xs mt-1 text-red-700'>Cause of Death is Required</p>}
                      </div>
                    </div>

                    <div className="sm:col-span-1">
                      <div className="mb-4">
                        <label htmlFor="nextOfKinId" className="block text-sm font-medium text-gray-700">
                          Next of Kin
                        </label>
                        <Select
                          options={getOptionsFromNextOfKin(nextOfKinList)} 
                          className="mt-1 block w-full text-base sm:text-sm rounded-md"
                          onChange={(selectedOption) => setValue('nextOfKinId', selectedOption.value)}
                          value={getOptionsFromNextOfKin(nextOfKinList).find(option => option.value === watch('nextOfKinId'))}
                          placeholder="Select next of kin"
                        />
                        {errors.nextOfKinId && (
                          <p className="text-red-500 text-xs mt-1">Next of Kin is required</p>
                        )}
                      </div>
                      <button type="button" className="rounded-md bg-indigo-600 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleOpenModal}>
                        Add new next of kin
                      </button>
                      <NextOfKinModal isOpen={isModalOpen} onClose={handleCloseModal} onNewNextOfKin={handleNewNextOfKin} />
                    </div>

                    <div className="sm:col-span-1">
                    <label htmlFor="dateOfDeath" className="block text-sm font-medium text-gray-700">Date of Demise:</label>
                    <div className="mt-1">
                    <input
                      type="date"
                      max={currentDate} 
                      id="dateOfDeath"
                      name="dateOfDeath"
                      {...register('dateOfDeath', { required: true })}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    {errors.dateOfDeath && <p className='text-xs mt-1 text-red-700'>Date of Demise is Required</p>}
                    </div>
                    </div>
      
                    <div className="sm:col-span-1">
                    <label htmlFor="dateDeposited" className="block text-sm font-medium text-gray-700">Date Deposited:</label>
                    <div className="mt-2">
                    <input type="date" 
                      max={currentDate} 
                      {...register('dateDeposited', { required: true, validate: value => value >= watch('dateOfDeath') || 'Date deposited must be equal to or after the date of death' })} 
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.dateDeposited && <p className='text-xs mt-1 text-red-700'>Date Deceased was Deposited is Required</p>}
                          <div className='mt-1'>
                       <p className='text-xs text-red-500 text-center'> Days in Morgue: {daysSpent} </p>
                    </div>

                    </div>
                    </div>
                       

                     <div className="flex items-center justify-between">
                    <input type="submit" value="Next" className="mt-2 bg-blue-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"/>
                  </div>
      
      </div>    

    </form>
</div>

</>
  );
};

export default DeceasedData;
