import React, {useState,useEffect} from 'react';
import { useForm, useFieldArray } from "react-hook-form";
import { RadioGroup } from '@headlessui/react'
import { collection, getDocs,  query, where } from 'firebase/firestore';
import { setReferralRewards} from '../ReferralAgents/setReferralRewards';
import { db,functions } from '../../firebase-config';
import { httpsCallable } from "firebase/functions";
import { generateUniqueId} from '../generateUniqueId';
import { checkStorageAvailability} from '../StorageManagement/checkStorageAvailability';
import {formatCurrency} from './helpers';


const DeceasedCost = ({ formData, onFormDataChange, onBack, onNext , referralAgentsList}) => {
    const { handleSubmit, watch, control, formState: { errors }, register,setValue } = useForm({defaultValues: formData,});
    const { fields, append, remove } = useFieldArray({control,name: "expenses" });
     const sendEmailNotification = httpsCallable(functions, 'sendEmailNotification');
     const [deceasedId, setDeceasedId] = useState(null);
    const [referralContact, setReferralContact] = useState(null);
    const watchIsReferred = watch('isReferred', false);
    const isReferred = watch('isReferred', false);
       const [storageTypes, setStorageTypes] = useState([]);
    const [selectedStorageType, setSelectedStorageType] = useState('');
    const [availableSpaces, setAvailableSpaces] = useState([]);
     const [selectedSpaceId, setSelectedSpaceId] = useState('');
     const [availableSpaceCount, setAvailableSpaceCount] = useState(0);
    const [spaceOptions, setSpaceOptions] = useState([]);


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
      }

    useEffect(() => {
           for (const field in formData) {
          setValue(field, formData[field]);
        }
      }, [formData, setValue]);



      const selectedStorageTypeValue = watch('selectedStorageType');


      useEffect(() => {
        const fetchAvailableSpaces = async () => {
            if (selectedStorageType) {
                const spacesRef = collection(db, 'storage', selectedStorageType, 'spaces');
                const q = query(spacesRef, where('occupied', '==', false));
                const snapshot = await getDocs(q);
                setSpaceOptions(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            } else {
                setSpaceOptions([]);
            }
        };
        fetchAvailableSpaces();
    }, [selectedStorageType]);


    const handleSpaceChange = (space) => {
        setSelectedSpaceId(space.id);
        setValue('selectedSpaceId', space.id, { shouldValidate: true });
      };

      useEffect(() => {
        if (formData.selectedSpaceId) {
          setSelectedSpaceId(formData.selectedSpaceId);
          setValue('selectedSpaceId', formData.selectedSpaceId);
        }
      }, [formData, setValue]);

    const onSubmit = data => {

        onFormDataChange({ ...data, selectedSpaceId });
        onNext();
      };


        const assignReferral = (deceasedId, referralContact) => {
            setReferralRewards((prevState) => {
            const referralIndex = prevState.findIndex((referral) => referral.referralContact === referralContact);

        if (referralIndex === -1) {
            return [
            ...prevState,
            {
                id: generateUniqueId(),
                deceasedRecords: [
                {
                    deceasedId,
                    referralContact,
                    status: 'pending',
                },
                ],
            },
            ];
        } else {
            const updatedReferral = {
            ...prevState[referralIndex],
            deceasedRecords: [
                ...prevState[referralIndex].deceasedRecords,
                {
                deceasedId,
                referralContact,
                status: 'pending',
                },
            ],
            };

            return [
            ...prevState.slice(0, referralIndex),
            updatedReferral,
            ...prevState.slice(referralIndex + 1),
            ];
        }
        });

            // Send an email notification to the admin
            sendEmailNotification({
                recipient: 'donotreply@elitefuneralhomes.com', // replace with the actual recipient email
                subject: 'New Referral Assignment',
                content: `A new referral assignment has been created. Here are the details: ${JSON.stringify(data)}`
            }).then(result => {
                console.log(result); // handle success
            }).catch(error => {
                console.error(error); // handle error
            });

        };

        // Add a new entry to the referralRewards state
        setReferralRewards((prevState) => [
            ...prevState,
            {
            id: generateUniqueId(),
            deceasedRecords: [
                {
                deceasedId,
                referralContact,
                status: 'pending',
                },
                            ],

            }

        ]);

        const adminEmail = 'notifications@elitefuneralhomes.com';
        const data = {
            recipient: adminEmail,
            subject: "Elite Funeral Homes Referral Program",
            content: `Hello Admin, \n\nA body was recently deposited at the morgue based on your recommendation.
            Our team will contact you shortly to validate this information. \n\nPlease do not respond to this email.\n\n
            Sincerely,\nElite Funeral Homes.`,
        };


        useEffect(() => {
            const fetchStorageTypes = async () => {
              const snapshot = await getDocs(collection(db, 'storage'));
              setStorageTypes(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            };
            fetchStorageTypes();
          }, []);

        useEffect(() => {
        const fetchAvailableSpaces = async () => {
            if (selectedStorageTypeValue) {
                const spacesRef = collection(db, 'storage', selectedStorageTypeValue, 'spaces');
                const q = query(spacesRef, where('occupied', '==', false));
                const snapshot = await getDocs(q);
                setAvailableSpaces(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            } else {
                setAvailableSpaces([]);
            }
        };
        fetchAvailableSpaces();
    }, [selectedStorageTypeValue]); // Now correctly dependent on watched valu

          const handleStorageTypeChange = async (event) => {
            setSelectedStorageType(event.target.value);
            setValue('selectedStorageType', event.target.value);

            // Call the function to check storage availability
            const count = await checkStorageAvailability(event.target.value);
            setAvailableSpaceCount(count);
        };


return (
    <div className="flex justify-center items-center bg-gray-100">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-5xl m-auto bg-white shadow-md rounded px-8 pt-1 pb-4 mb-4">
        <div className=" mt-6 grid grid-cols-1 gap-4">

                {/* Mortician Comments field */}
                <div className="space-y-2">
                    <label htmlFor="morticianComments" className="block text-sm font-medium leading-5 text-gray-700">Morticians Comments</label>
                    <textarea
                    id="morticianComments"
                    name="morticianComments"
                    rows={3}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register('morticianComments', { required: true })}
                    />
                    <p className="text-xs italic text-gray-500">Write details about the state of the body and materials required.</p>
                </div>


      <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
        {/* Storage Type Select */}
        <div className="sm:col-span-1">
          <label className="block text-sm font-medium text-gray-700">Storage Type</label>
          <select {...register('selectedStorageType', { required: 'Storage type is required' })}
          onChange={handleStorageTypeChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            <option value="">Select a storage type</option>
            {storageTypes.map(type => (
              <option key={type.id} value={type.id}>
                {`${type.description} - ${formatCurrency(type.sellingPrice)}`}
              </option>
            ))}
          </select>
          {errors.selectedStorageType && <p className="text-red-500 text-xs">{errors.selectedStorageType.message}</p>}
        </div>
        {availableSpaceCount > 0 ? (
                    <>
                    <RadioGroup value={selectedSpaceId} onChange={handleSpaceChange} >

                       <p className="text-green-900 mb-2">Select a Storage Space</p>
                    <RadioGroup.Label className="sr-only">Choose a space</RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-6">
                      {spaceOptions.map((space) => (
                        <RadioGroup.Option
                          key={space.id}
                          value={space}
                          className={({ active, checked }) => classNames(
                            'cursor-pointer focus:outline-none',
                            active ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
                            checked ? 'bg-indigo-600 text-white hover:bg-indigo-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                            'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1'
                          )}
                        >
                          <RadioGroup.Label as="span">{space.id}</RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                    </RadioGroup>

                    </>
) : (
  <p className="text-red-500">No spaces available</p>
)}

      </div>

                          {/* Is Referred field */}
                        <div className="space-y-2">
                            <div className="flex items-center">
                            <input id="isReferred"
                             {...register('isReferred') }

                            type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            <label htmlFor="isReferred" className="ml-2 block text-sm font-medium leading-5 text-gray-700">Tick if Customer was Referred</label>
                            </div>
                            {watchIsReferred && (
                            <div>
                                <label htmlFor="referralAgentId" className="block text-sm font-medium leading-5 text-gray-700">Select Referral Agent</label>
                                <select
                                {...register('referralAgentId')}
                                name="referralAgentId"
                                id="referralAgentId"
                                {...register('referralAgentId', { required: isReferred ? 'Referral Agent is required' : false })}
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                <option value="">Select Referral Agent</option>
                                {referralAgentsList.map((agent) => (
                                    <option key={agent.id} value={agent.id}>
                                    {agent.firstName} {agent.lastName}
                                    </option>
                                ))}
                                </select>
                                {errors.referralAgentId && (
                                <p className="mt-2 text-sm text-red-600">{errors.referralAgentId.message}</p>
                                )}
                            </div>
                            )}
                        </div>

                        {/* Expenses fields */}
                        <fieldset className="space-y-4">
                                <legend className="text-xl font-medium leading-2 text-gray-700 mb-2">Add Other Expenses</legend>
                                {fields.map((field, index) => (
                                    <div key={`${field.id}-${index}`} className="grid grid-cols-[10fr,3fr,6fr,1fr] gap-2 items-center">
                                       {/* Dropdown for Description */}
                                  <div className="md:col-span-1">
                                      <label htmlFor={`expenses.${index}.description`} className="block text-sm font-medium text-gray-700">Service Name</label>
                                      <select
                                        id={`expenses.${index}.description`}
                                        {...register(`expenses.${index}.description`)}
                                        defaultValue={field.description}
                                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    >
                                        <option value="">Select Service</option>
                                        <option value="Embalment">Embalment</option>
                                        <option value="White Cloth">White Cloth</option>
                                        <option value="Washing and Dressing">Washing and Dressing</option>
                                        <option value="Tag">Tag</option>
                                        <option value="Ambulance">Ambulance</option>
                                        <option value="Storage Registration">Storage Registration</option>
                                        <option value="Wound Dressing">Wound Dressing</option>
                                        <option value="Autopsy">Autopsy</option>
                                        <option value="Barbing and Shaving">Barbing and Shaving</option>
                                        <option value="Make Up">Make Up</option>
                                        <option value="Correction">Correction</option>
                                        
                                    </select>
                                    </div>

                                      {/* Quantity Input */}
                                    <div className="md:col-span-1">
                                        <label htmlFor={`expenses.${index}.quantity`} className="block text-sm font-medium text-gray-700">Quantity</label>
                                    <input
                                        {...register(`expenses.${index}.quantity`)}
                                        defaultValue={field.quantity}
                                        placeholder="Qty"
                                        type="number"
                                        className="mt-1 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    </div>

                                     {/* Unit Cost Input */}
                                    <div className="md:col-span-1">
                                        <label htmlFor={`expenses.${index}.unitCost`} className="block text-sm font-medium text-gray-700">Unit Cost</label>
                                    <input
                                        id={`expenses.${index}.unitCost`}
                                        {...register(`expenses.${index}.unitCost`)}
                                        defaultValue={field.unitCost}
                                        placeholder="Unit Cost"
                                        type="number"
                                        className="mt-1 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    </div>

                                    <div className="md:col-span-1 flex justify-end items-start">
                                    <button
                                        type="button"
                                        className="mt-1 bg-red-500 text-white px-2 py-1 rounded"
                                        onClick={() => remove(index)}
                                    >
                                        X
                                    </button>
                                    </div>
                                    </div>

                                ))}
                                <button
                                    type="button"
                                    onClick={() => append({ description: "", quantity: 1, unitCost: 0 })}
                                    className="mt-2 bg-red-500 text-white text-sm hover:bg-green-600 px-2 py-1 rounded"
                                >
                                    Add Expense
                                </button>
                            </fieldset>



                        {/* Buttons */}
                        <div className="flex items-center justify-between">
                            <button
                            type="button"
                            onClick={onBack}
                            className="mt-2 bg-amber-500 text-white text-sm hover:bg-green-600 px-4 py-2 rounded font-medium  shadow-sm"
                            >
                            Back
                            </button>

                            <button
                            type="submit"
                            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm
                            text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                            Next
                            </button>
                        </div>

                        </div>
                  </form>
    </div>
  );

};

export default DeceasedCost;
