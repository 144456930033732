import React, { useState, useEffect } from 'react';
import { fetchNextOfKinList as fetchNextOfKin } from '../NextOfKin/nextOfKinService';
import { fetchReferralAgents } from '../../components/ReferralAgents/referralAgentService';
import { CogIcon } from '@heroicons/react/20/solid';
import useRoleRestriction from "../../context/useRoleRestriction";
import AccessDenied from '../AccessDenied';
import DeceasedData from "./DeceasedData";
import DeceasedCost from "./DeceasedCost";
import RegistrationComplete from './RegistrationComplete';

import ConfirmationPage from './ConfirmationPage';


const DeceasedFormContainer = () => {
  const [nextOfKinList, setNextOfKinList] = useState(null);
  const [referralAgentsList, setReferralAgentsList] = useState(null);
  const allowedRoles = ["Admin", "Finance", "Mortician"];
  const isAllowed = useRoleRestriction(allowedRoles);
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState('Step 1');
  const [formData, setFormData] = useState({});
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleFormSubmitStatus = (status) => {
    setSubmitStatus(status);
  }



  const handleNewNextOfKin = (newNextOfKin) => {
    setNextOfKinList([...nextOfKinList, newNextOfKin]);
  };

  const handleFormDataChange = (newData) => {
    setFormData({...formData, ...newData});
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchedNextOfKin = await fetchNextOfKin();
      setNextOfKinList(fetchedNextOfKin);

      const fetchedReferralAgents = await fetchReferralAgents();
      setReferralAgentsList(fetchedReferralAgents);

      setIsLoading(false);
    };

    fetchData();
  }, []);

  const steps = [
    {
      id: 'Step 1',
      name: 'Provide Deceased Data',
      component: (
        <DeceasedData
          nextOfKinList={nextOfKinList}
          onNewNextOfKin={handleNewNextOfKin}
          referralAgentsList={referralAgentsList}
          formData={formData}
          onFormDataChange={handleFormDataChange}
          onNext={() => setCurrentStep('Step 2')}
        />
      ),
    },
    {
      id: 'Step 2',
      name: 'Add Initial Morgue Fees',
      component: (
        <DeceasedCost
          referralAgentsList={referralAgentsList}
          formData={formData}
          onFormDataChange={handleFormDataChange}
          onBack={() => {
            console.log('Going back from Step 2');
            setCurrentStep('Step 1');
          }}
          onNext={() => setCurrentStep('Step 3')}
        />
      ),
    },
    {
      id: 'Step 3',
      name: 'Review and Confirm',
      component: (
        <ConfirmationPage 
        formData={formData}
        onBack={() => setCurrentStep('Step 2')}
        onNext={() => setCurrentStep('Step 4')}
        onFormSubmitStatus={handleFormSubmitStatus}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        />
      ),
    },
    {
      id: 'Step 4',
      name: 'Registration Complete',
      component: <RegistrationComplete />,
    },
    


  ];

  const getCurrentStep = () => {
    return steps.find(step => step.id === currentStep);
  };

  if (!isAllowed) {
    return <AccessDenied />
  }

  return (
    <>
      <nav aria-label="Progress">
        <ol className="space-y-4 md:flex md:space-x-1 md:space-y-0 mt-4 mb-2 text-center  gap-10 px-14 sm:px-14">
        {steps.map((step, index) => (
  <li key={`${step.name}-${index}`} className="md:flex-1">
              {step.id < currentStep ? (
                <div className="group flex flex-col border-l-2 border-indigo-600 py-2 pl-1 text-center hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2">
                  <span className="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">{step.id}</span>
                  <span className="text-sm font-medium ">{step.name}</span>
                </div>
              ) : step.id === currentStep ? (
                <div className="flex flex-col border-l-4 border-indigo-600 py-2 pl-2 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2">
                  <span className="text-sm font-medium text-indigo-600">{step.id}</span>
                  <span className="text-sm font-medium">{step.name}</span>
                </div>
              ) : (
                <div className="group flex flex-col border-l-4 border-gray-200 py-2 pl-2 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2">
                  <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                  <span className="text-sm font-medium">{step.name}</span>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>

      
                      
      <div className="flex justify-center items-center py-2">
        <div className="w-full ">
          {!isLoading && nextOfKinList && referralAgentsList ? (
            getCurrentStep().component
          ) : (
            <div className="flex justify-center items-center">
              <CogIcon className="animate-spin h-8 w-8 text-red-500 mt-20" />
              <p className='items-center' >Loading ...</p>
            </div>
          )}
        </div>

        {submitStatus && <p>{submitStatus}</p>}


      </div>
    </>
  );
};

export default DeceasedFormContainer;
