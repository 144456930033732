import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { collection, addDoc, updateDoc, doc, getDoc, Timestamp, getDocs, arrayUnion, query, where, orderBy } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { CheckIcon} from '@heroicons/react/24/solid';
import { formatCurrency, FormatStorage, capitalizeFirstLetter, convertTimestampToString } from '../Deceased/helpers';

Modal.setAppElement('#root');


function StorageModal({ isOpen, onRequestClose, deceased }) {
  const [selectedStorage, setSelectedStorage] = useState('');
  const [availableSpaces, setAvailableSpaces] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [currentAssignment, setCurrentAssignment] = useState(null);
  const [assignedStorage, setAssignedStorage] = useState(null);
  const [assignedAtDate, setAssignedAtDate] = useState('');



  
 const fetchCurrentAssignment = useCallback(async  () => {
  if (deceased && deceased.id) {
    const storageAllocationsRef = collection(db, 'storageAllocations');
    // Assuming 'assignedAt' is a timestamp field indicating when the allocation was made
    const q = query(storageAllocationsRef, where('deceasedId', '==', deceased.id), orderBy('assignedAt', 'desc'));

    try {
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Assuming the most recent allocation is the current one
        const currentStorageData = querySnapshot.docs[0].data();
        setCurrentAssignment(currentStorageData);
      } else {
        setCurrentAssignment(null);
      }
    } catch (error) {
      console.error("Error fetching current assignment:", error);
      setCurrentAssignment(null);
    }
  } else {
    setCurrentAssignment(null);
  }
}, [deceased]); 
  
    useEffect(() => {
      if (deceased && deceased.id) {
        fetchCurrentAssignment();
      }
    }, [deceased, fetchCurrentAssignment]);
  
   
  const handleStorageChange = (event) => {
    setSelectedStorage(event.target.value);
  };


  const handleUnassignStorage = async () => {
    if (deceased && deceased.id) {
      const storageAllocationsRef = collection(db, 'storageAllocations');
      const q = query(storageAllocationsRef, where('deceasedId', '==', deceased.id), orderBy('assignedAt', 'desc'));
  
      try {
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const currentStorageAllocationDoc = querySnapshot.docs[0];
          const currentStorageAllocationData = currentStorageAllocationDoc.data();
  
          // Update the corresponding space to be unoccupied
          const spaceRef = doc(db, 'storage', currentStorageAllocationData.storageType, 'spaces', currentStorageAllocationData.storageId);
          await updateDoc(spaceRef, { occupied: false, deceasedId: '' });
  
          // Update the storage allocation to include the unassignment date
          const unassignmentTimestamp = Timestamp.now();
          await updateDoc(currentStorageAllocationDoc.ref, {
            dateUnassigned: unassignmentTimestamp
          });
  
          // Update the deceased document
          const deceasedRef = doc(db, 'deceased', deceased.id);
          const deceasedSnap = await getDoc(deceasedRef);
          const deceasedData = deceasedSnap.data();
  
          if (deceasedData.allocations && deceasedData.allocations.length > 0) {
            const updatedAllocations = deceasedData.allocations.map(allocation => {
              if (allocation.storageNumber === currentStorageAllocationData.storageId && !allocation.dateUnassigned) {
                return { ...allocation, dateUnassigned: unassignmentTimestamp };
              }
              return allocation;
            });
  
            await updateDoc(deceasedRef, { allocations: updatedAllocations });
          }
        }
  
        setCurrentAssignment(null);
      } catch (error) {
        console.error("Error unassigning storage:", error);
        // Handle the error as required
      }
    } else {
      console.log("Deceased or deceased.id is undefined");
      setCurrentAssignment(null);
    }
  };
  
  
  

  const handleAssignButtonClick = async () => {
    if (!selectedStorage) {
      console.error("No storage selected.");
      return;
    }
  
    if (!assignedAtDate) {
      console.error("No assignment date selected.");
      return;
    }
  
    await handleUnassignStorage();
  
    const storageSpace = availableSpaces.find(option => option.id === selectedStorage);
    const storageSpaceRef = doc(db, 'storage', storageSpace.storageType, 'spaces', storageSpace.id);
    await updateDoc(storageSpaceRef, { occupied: true, deceasedId: deceased.id });
  
    const assignmentTimestamp = Timestamp.fromDate(new Date(assignedAtDate));
    
    const storageAllocationRef = collection(db, 'storageAllocations');
    const storageAllocationId = await addDoc(storageAllocationRef, {
      deceasedId: deceased.id,
      storageId: storageSpace.id,
      storageType: storageSpace.storageType,
      storageFees: storageSpace.sellingPrice,
      assignedAt: assignmentTimestamp,
    });
  
    const deceasedRef = doc(db, 'deceased', deceased.id);
    await updateDoc(deceasedRef, {
      allocations: arrayUnion({
        dateAssigned: assignmentTimestamp,
        storageFees: storageSpace.sellingPrice,
        storageType: storageSpace.storageType,
        storageNumber: storageSpace.id,
      })
    });
  
    setAssignedStorage(storageSpace);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
      onRequestClose();
    }, 2000);
  };
  
  
  // Fetch storage services from Firestore
  useEffect(() => {
    const fetchStorageSpaces = async () => {
      const storageSnapshot = await getDocs(collection(db, 'storage'));
      const spaces = [];
      for (let doc of storageSnapshot.docs) {
        const storageData = doc.data(); // Get storage data
        const spaceSnapshot = await getDocs(collection(doc.ref, 'spaces'));
        spaceSnapshot.forEach(spaceDoc => {
          if (!spaceDoc.data().occupied) {
            spaces.push({ 
              ...spaceDoc.data(), 
              id: spaceDoc.id, 
              storageType: doc.id, 
              sellingPrice: storageData.sellingPrice // Include sellingPrice from storage document
            });
          }
        });
      }
      setAvailableSpaces(spaces);
    };
  
    fetchStorageSpaces();
  }, []);
  
  

 

  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Deceased Storage Allocation"
    className="Modal"
    overlayClassName="Overlay"
  >
    <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800">
      Change Storage for {deceased ? `${capitalizeFirstLetter(deceased.firstName)} ${capitalizeFirstLetter(deceased.lastName)}` : ''}
    </h2>
  
    {showSuccess && (
      <div className="flex items-center text-green-500 mb-2">
        <CheckIcon className="w-5 h-5 mr-1" />
        <span>Storage assigned successfully!</span>
      </div>
    )}
  
    <div className="mt-4">
      {currentAssignment ? (
        <div className="mb-4">
          <h3 className="text-md sm:text-lg font-semibold text-gray-700">Current Storage Assignment:</h3>
          <p className="text-sm sm:text-md text-gray-600">Storage Type: {FormatStorage(currentAssignment.storageType)}</p>
          <p className="text-sm sm:text-md text-gray-600">Storage ID: {currentAssignment.storageId}</p>
          <p className="text-sm sm:text-md text-gray-600">Assigned At: {convertTimestampToString(currentAssignment.assignedAt)}</p>
        </div>
      ) : (
        <p className="text-sm sm:text-md text-gray-600">No current storage assignment.</p>
      )}
    </div>
  
    <div className="mt-4">
      <label className="block text-md sm:text-lg font-semibold text-gray-700 mb-2">
        Select a Storage:
      </label>
      <select 
        value={selectedStorage} 
        onChange={handleStorageChange} 
        className="text-sm sm:text-md form-select block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      >
        <option value="">Select a storage</option>
        {availableSpaces.map((option) => (
          <option key={option.id} value={option.id}>
            {`${FormatStorage(option.storageType)} - Storage ID: ${option.id} - ${formatCurrency(option.sellingPrice)}`}
          </option>
        ))}
      </select>
    </div>

    <div className="mt-4">
  <label className="block text-md sm:text-lg font-semibold text-gray-700 mb-2">
    Select Assignment Date:
  </label>
  <input
    type="date"
    value={assignedAtDate}
    onChange={(e) => setAssignedAtDate(e.target.value)}
    className="text-sm sm:text-md form-input block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
  />
</div>

  
    {/* Buttons */}
    <div className="flex space-x-4 mt-4">
      <button
        onClick={handleAssignButtonClick}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
        id='assignButton'
      >
        Assign Storage
      </button>
      <button
        onClick={handleUnassignStorage}
        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
      >
        Unassign Storage
      </button>
      <button
        onClick={onRequestClose}
        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
      >
        Close
      </button>
    </div>
  </Modal>
  
  );
}

export default StorageModal;
